@import "reset";
@import "descriptions";

@import "fonts";

html, body {
  width:100%;
  height: 100%;

  &.-overflow-hidden {
    overflow: hidden;
  }
}

body {
  font-family: $font-family-vksans;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
  background: #EEF1F4;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  transition: background 0.3s linear;

  &.-p404,
  &.-webcalls,
  &.-black {
    color: $white;
    background: $black;

    .page {
      margin-bottom: 0;
    }
  }

  &.-webcalls,
  &.-vkteams,
  &.-agent {
    .page {
      display: table;
      width: 100%;
      height: 100%;
    }
  }

  &.-webcalls {
    .page {
      background: url('/grid-top-mobile.png') no-repeat 50% 0;

      @media (min-width: 768px) {
        background: url('/grid-bottom.png') no-repeat 50% 100%;
      }
    }
  }

  &.-vkteams {
    color: #000000;
    background: #F3F6FD;

    .page {
      margin-bottom: 0;
    }
  }

  &.-agent {
    color: #000000;
    background: #ffffff;

    .page {
      margin-bottom: 0;
    }
  }
}

input[type="text"], textarea {
  -webkit-appearance: none;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-weight: 700;
}
