@mixin placeholderColor($color) {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $color;
  }
}

.im-text-input {
  position: relative;
  height: 48px;
  box-sizing: border-box;
  border-radius: 24px;
  font-size: 15px;
  padding: 0 20px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  color: #111;
  background: #fff;
  @include placeholderColor(#838690);
  box-shadow: 0 0 1px 0.2px rgba(0, 0, 0, 0.15), 0 1px 12px rgba(0, 0, 0, 0.15);

  &:focus {
    box-shadow: 0 0 1px 0.2px rgba(0, 0, 0, 0.2), 0 1px 12px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: #f3f5f8;
  }
}

.im-auth-formwrap {
  display: flex;
  width: 300px;
  max-width: 90%;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  align-items: center;
}

.im-auth-form {
  max-width: 100%;
  padding: 48px 0;
  box-sizing: border-box;
  text-align: center;

  .im-input-country-phone {
    width: 300px;
    max-width: 100%;
    margin-top: 28px;
  }

  .im-text-input {
    width: 300px;
    max-width: 100%;
  }
}

.im-auth-form-password {
  @extend .im-auth-form;
}

.im-auth-title {
  font-weight: bold;
  display: block;
  font-size: 22px;
  letter-spacing: -0.02em;
  user-select: none;
}

.im-auth-description {
  display: block;
  font-size: 15px;
  line-height: 144%;
  margin-top: 16px;
  user-select: none;
}

.im-auth-input-login {
  margin-top: 16px;
}

.im-auth-submit {
  display: inline-block;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 40px;
  height: 40px;
  min-width: 110px;
  text-align: center;
  border-radius: 20px;
  font-size: 15px;
  border: none;
  outline: none;
  padding: 0 32px 12px;
  margin-top: 16px;
  -webkit-appearance: none;
  cursor: pointer;
  color: #fff;
  background: #2d90f5;

  &:hover {
    background: #2c84df;
  }

  &:active {
    background: #317bc7;
  }

  &:disabled {
    cursor: default;
    background: #cccfd8;
  }
}

.im-marker {
  color: #1b85f1;
}

.im-auth-input-pswd {
  margin-top: 20px;
}

.im-auth-button-blank {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 5px;
  height: 32px;
  min-width: 32px;
  font-size: 14px;
  text-transform: none;
  box-sizing: border-box;
  color: #838690;

  &:hover {
    color: #7a7e8b;
  }

  &:active {
    color: #707482;
  }
  background-color: transparent;
}

.im-auth-switch-form {
  position: absolute;
  z-index: 1;

  display: block;
  width: 32px;
  top: 12px;
  left: 12px;

  background-color: #b7bcc9;
  mask: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m14.25 18.17-6-5.944 6-6.057' stroke='%23333' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  -webkit-mask: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m14.25 18.17-6-5.944 6-6.057' stroke='%23333' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-size: cover;
  -webkit-mask-size: cover;
}

.im-auth-error {
  display: block;
  margin-top: 16px;
  min-height: 20px;
  line-height: 20px;
  font-size: 15px;
  color: #f83e33;

  &:empty {
    visibility: hidden;
  }
}
