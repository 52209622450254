.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 1000;

  @media (min-width: 980px) {
    height: 130px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 0;
  }

  &:before {
    display: none;
    left: 0;
    background: linear-gradient(179.89deg, #ffffff 24.96%, rgba(255, 255, 255, 0) 96.09%);

    @media (min-width: 768px) {
      display: block;
      width: 44%;
    }

    @media (min-width: 980px) {
      width: 35%;
    }

    @media (min-width: 1900px) {
      width: 27%;
    }

    @media (min-width: 2200px) {
      width: 18%;
    }
  }

  &:after {
    width: 100%;
    right: 0;
    background: linear-gradient(179.89deg, #EEF1F4 24.96%, rgba(238, 241, 244, 0) 96.09%);

    @media (min-width: 768px) {
      width: 56%;
    }

    @media (min-width: 980px) {
      width: 65%;
    }

    @media (min-width: 1900px) {
      width: 73%;
    }

    @media (min-width: 2200px) {
      width: 82%;
    }
  }

  &.-shadow-one {
    &:before {
      display: none;
    }

    &:after {
      left: 0;
      width: auto;
      background: linear-gradient(179.89deg, #EEF1F4 24.96%, rgba(238, 241, 244, 0) 96.09%);
    }
  }

  &__logo {
    position: absolute;
    top: 18px;
    left: 8px;
    width: 64px;
    height: 64px;
    z-index: 1;

    @media (min-width: 980px) {
      width: 221px;
      height: 72px;
      top: 32px;
      left: 50px;
    }

    svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 64px;
      height: 64px;

      @media (min-width: 980px){
        top: 0;
        left: 0;
        width: 73px;
        height: 73px;
      }
    }
  }
}


.-p404 .header,
.-black .header {
  &:before {
    display: none;
  }

  &:after {
    left: 0;
    width: auto;
    background: linear-gradient(179.89deg, #000000 24.96%, rgba(0, 0, 0, 0) 96.09%);
  }
}

.-vkteams .header {
  &:before {
    display: none;
  }

  &:after {
    left: 0;
    width: auto;
    background: linear-gradient(179.89deg, #F3F6FD 70%, rgba(243, 246, 253, 0) 100%);

    @media (min-width: 768px) {
      background: linear-gradient(179.89deg, #F3F6FD 24.96%, rgba(243, 246, 253, 0) 96.09%);
    }
  }

  &__logo {
    width: 177px;
    height: 50px;

    svg {
      width: 177px;
      height: 50px;
    }
  }
}