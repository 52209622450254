.download-page {
  padding: 331px 16px 40px 16px;

  @media (min-width: 375px) {
    padding: 341px 28px 40px 28px;
  }

  @media (min-width: 768px) {
    padding: 322px 0 40px 0;
  }

  @media (min-width: 980px) {
    padding-top: 392px;
  }

  @media (min-width: 1900px) {
    padding-top: 395px;
  }

  @media (min-width: 2200px) {
    padding-top: 448px;
  }
  &__rtfm-button {
    height: 56px;
    width: 294px;
    border-radius: 33.6px !important;
    background: #0077FF !important;
    margin: 0 auto !important;
    color: #fff !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-style: normal !important;
    line-height: 26px !important;
    text-transform: none !important;

    @media (min-width: 375px) {
      width: 294px;
    }

    @media (min-width: 768px) {
      width: 294px;
    }

    @media (min-width: 980px) {
      width: 294px;
    }

    @media (min-width: 1200px) {
      width: 294px;
    }

    @media (min-width: 1440px) {
      width: 294px;
    }

    @media (min-width: 1900px) {
      width: 294px;
    }
  }

  &__rtfm-title {
    margin: 30px auto;

    @media (min-width: 375px) {
      width: 250px;
    }

    @media (min-width: 768px) {
      width: 452px;
    }

    @media (min-width: 980px) {
      width: 452px;
    }

    @media (min-width: 1200px) {
      width: 452px;
    }

    @media (min-width: 1440px) {
      width: 452px;
    }

    @media (min-width: 1900px) {
      width: 452px;
    }
  }

  &__rtfm-box {
    height: 240px;
    margin: 30px auto;

    @media (min-width: 375px) {
      width: 300px;
    }

    @media (min-width: 768px) {
      width: 600px;
    }

    @media (min-width: 980px) {
      width: 600px;
    }

    @media (min-width: 1200px) {
      width: 600px;
    }

    @media (min-width: 1440px) {
      width: 600px;
    }

    @media (min-width: 1900px) {
      width: 600px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 24px;

    @media (min-width: 375px) {
      margin: 36px auto 0 auto;
      max-width: 320px;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 48px;
      max-width: 840px;
    }

    @media (min-width: 980px) {
      font-size: 16px;
      line-height: 25px;
      margin-top: 62px;
    }

    @media (min-width: 1200px) {
      margin: 66px 45px 0 45px;
      padding-left: 0;
      padding-right: 0;
      max-width: inherit;

      &:after {
        content: '';
        width: calc(33% - 26px);
        height: 0;
      }
    }

    @media (min-width: 1440px) {
      margin-top: 97px;

      &:after {
        width: calc(33% - 30px);
      }
    }

    @media (min-width: 1900px) {
      max-width: 1656px;
      margin-top: 103px;
      margin-left: auto;
      margin-right: auto;

      &:after {
        width: calc(33% - 110px);
      }
    }
  }

  &__item {
    position: relative;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 37px;
    padding-left: 48px;

    &:before {
      content: '';
      position: absolute;
      top: -16px;
      left: -12px;
      width: 56px;
      height: 56px;
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media (min-width: 768px) {
      width: calc(50% - 30px);
      margin-bottom: 56px;
      padding-left: 68px;

      &:before {
        top: -17px;
        left: 0;
        width: 60px;
        height: 60px;
      }
    }

    @media (min-width: 980px) {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 65px;
      padding-left: 88px;

      &:before {
        width: 80px;
        height: 80px;
      }
    }

    @media (min-width: 1200px) {
      width: calc(33% - 30px);
      padding-left: 84px;
      order: 0 !important;

      &:before {
        top: -12px;
      }
    }

    @media (min-width: 1440px) {
      margin-bottom: 60px;
      padding-left: 112px;

      &:before {
        top: -22px;
        width: 108px;
        height: 108px;
      }
    }

    @media (min-width: 1900px) {
      width: calc(33% - 110px);
    }

    a {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #22F200;
        transition: all 0.1s cubic-bezier(.42, 0, .58, 1);
      }

      &:hover:after {
        box-shadow: 0 1px 10px 2px #22f200;
      }

      &:not(:last-child) {
        margin-right: 15px;

        &:before {
          content: '';
          position: absolute;
          top: 6px;
          right: -9px;
          width: 1px;
          height: 16px;
          background: #ffffff;
        }
      }
    }

    li:not(:last-child) {
      margin-bottom: 6px;
    }

    &.-windows {
      @media (min-width: 768px) and (max-width: 1199px) {
        order: 1;
      }

      @media (max-width: 767px) {
        order: 4;
      }

      &:before {
        background-image: url('../../i/ic-windows-vkteams.png');
      }
    }

    &.-web {
      @media (min-width: 768px) and (max-width: 1199px) {
        order: 2;
      }

      @media (max-width: 767px) {
        order: 3;
      }

      &:before {
        background-image: url('../../i/ic-web-vkteams.png');
      }
    }

    &.-android {
      @media (min-width: 768px) and (max-width: 1199px) {
        order: 5 !important;
      }

      @media (max-width: 767px) {
        order: 8;
      }

      &:before {
        background-image: url('../../i/ic-android-vkteams.png');
      }
    }

    &.-linux {
      @media (min-width: 768px) and (max-width: 1199px) {
        order: 3;
      }

      @media (max-width: 767px) {
        order: 6;
      }

      &:before {
        background-image: url('../../i/ic-linux-vkteams.png');
      }
    }

    &.-mac {
      @media (min-width: 768px) and (max-width: 1199px) {
        order: 4;
      }

      @media (max-width: 767px) {
        order: 5;
      }

      &:before {
        background-image: url('../../i/ic-mac-vkteams.png');
      }
    }

    &.-ios {
      @media (min-width: 768px) and (max-width: 1199px) {
        order: 6 !important;
      }

      @media (max-width: 767px) {
        order: 7;
      }

      &:before {
        background-image: url('../../i/ic-mac-vkteams.png');
      }
    }
    &.-iosdl {
      &:before {
        background-image: url('../../i/ic-mac-vkteams.png');
      }
    }
  }

  &__title {
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
    margin-bottom: 4px;

    @media (min-width: 768px) {
      margin-bottom: 14px;
    }

    @media (min-width: 980px) {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    @media (min-width: 1440px) {
      font-size: 26px;
      line-height: 33px;
      margin-bottom: 11px;
    }
  }

  &__text {
    text-align: center;
    margin-top: 24px;

    p {
      margin-top: 52px;
      font-size: 17px;
      line-height: 22px;
      text-transform: uppercase;
      opacity: 0.5;
    }
  }

  &__note {
    text-align: center;
    margin-top: 24px;
    font-size: 21px;
    line-height: 27px;
    color: #000000;

    a {
      color: #0077FF;

      &:hover {
        color: #0077FF;
      }

      &.link__copy {
        display: inline-block;

        &.-copy {
          font-size: 0;
          line-height: 0;
          color: #B1C5F2;

          &:before {
            content: 'скопировано';
            font-size: 20px;
            line-height: 24px;
      
            @media (min-width: 1440px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  h1 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
    color: #aeaeae;

    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 45px;
    }

    @media (min-width: 980px) {
      font-size: 41px;
      line-height: 52px;
    }

    @media (min-width: 1200px) {
      font-size: 46px;
      line-height: 59px;
    }

    @media (min-width: 1440px) {
      font-size: 46px;
      line-height: 59px;
    }

    @media (min-width: 1900px) {
      font-size: 54px;
      line-height: 69px;
    }

    span {
      color: #ffffff;
    }
  }

  h2 {
    text-align: center;
    color: #000;
    font-size: 25px;

    @media (min-width: 768px) {
      font-size: 25px;
      line-height: 25px;
    }

    @media (min-width: 980px) {
      font-size: 31px;
      line-height: 31px;
    }

    @media (min-width: 1200px) {
      font-size: 36px;
      line-height: 59px;
    }

    @media (min-width: 1440px) {
      font-size: 36px;
      line-height: 59px;
    }

    @media (min-width: 1900px) {
      font-size: 34px;
      line-height: 69px;
    }
  }

  h3 {
    font-size: 17px;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
    color: #000000;

    @media (min-width: 768px) {
      font-size: 21px;
      line-height: 45px;
    }

    @media (min-width: 980px) {
      font-size: 24px;
      line-height: 52px;
    }

    @media (min-width: 1200px) {
      font-size: 27px;
      line-height: 59px;
    }

    @media (min-width: 1440px) {
      font-size: 27px;
      line-height: 59px;
    }

    @media (min-width: 1900px) {
      font-size: 32px;
      line-height: 69px;
    }

    span {
      color: #ffffff;
    }
  }

  &-features {
    margin: 80px auto;
    max-width: 1200px;

    @media (min-width: 980px) {
      width: 76%;
      margin-top: 180px;
      margin-bottom: 160px;
    }

    &__block {
      &:not(:last-child) {
        margin-bottom: 90px;
      }

      @media (min-width: 980px) {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 140px;
        }
      }

      &.-vbottom {
        align-items: flex-end;
      }

      &.-invert {
        @media (min-width: 980px) {
          flex-direction: row-reverse;

          .download-page-features__text {
            margin-left: 0;
          }
        }
      }
    }

    &__col {
      @media (min-width: 980px) {
        width: 50%;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 22px;
      max-width: 409px;

      margin: 0 auto;

      @media (min-width: 980px) {
        margin: 0 0 0 70px;
        text-align: left;
      }

      @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 24px;
      }

      h3 {
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 15px;
        text-align: left;

        @media (min-width: 1200px) {
          font-size: 42px;
          line-height: 54px;
        }
      }

      a {
        color: #0077FF;

        &:hover {
          color: #0077FF;
        }
      }
    }

    &__img {
      position: relative;
      margin: 0 auto;

      @media (min-width: 980px) {
        margin: 0;
      }

      img {
        display: block;
        width: 100%;

        @media (min-width: 980px) {
          position: absolute;
        }
      }

      &.-features1 {
        max-width: 400px;

        @media (min-width: 980px) {
          margin-left: 40px;
          max-width: 326px;
          height: 367px;
        }

        img {
          @media (min-width: 980px) {
            top: -38px;
            left: -60px;
            width: 491px;
          }
        }
      }

      &.-features2 {
        max-width: 409px;

        @media (min-width: 980px) {
          height: 442px;
        }

        img {
          @media (min-width: 980px) {
            top: -33px;
            left: -53px;
            width: 515px;
          }
        }
      }

      &.-features3 {
        max-width: 411px;

        @media (min-width: 980px) {
          height: 507px;
          margin-left: 40px;
        }

        img {
          @media (min-width: 980px) {
            top: -33px;
            left: -53px;
            width: 516px;
          }
        }
      }

      &.-features4 {
        max-width: 401px;

        @media (min-width: 980px) {
          height: 426px;
        }

        img {
          @media (min-width: 980px) {
            top: -33px;
            left: -48px;
            width: 499px;
          }
        }
      }

      &.-features5 {
        max-width: 262px;

        @media (min-width: 980px) {
          margin-left: 144px;
          height: 466px;
        }

        img {
          @media (min-width: 980px) {
            top: -43px;
            left: -70px;
            width: 399px;
          }
        }
      }

      &.-features6 {
        max-width: 367px;

        @media (min-width: 980px) {
          height: 520px;
        }

        img {
          @media (min-width: 980px) {
            top: -33px;
            left: -53px;
            width: 472px;
          }
        }
      }
    }
  }
}

.background_default {
  background: url('../../i/download-page-bg-vk-teams.png') no-repeat 50% 138px;
  background-size: 318px auto;

  @media (min-width: 375px) {
    background-position: 50% 120px;
    background-size: 290px auto;
  }

  @media (min-width: 768px) {
    background-position: 50% 100px;
    background-size: 310px auto;
  }

  @media (min-width: 980px) {
    background-position: 50% 100px;
    background-size: 378px auto;
  }

  @media (min-width: 1900px) {
    background-position: 50% 121px;
    background-size: 408px auto;
  }

  @media (min-width: 2200px) {
    background-position: 50% 124px;
    background-size: 468px auto;
  }
}

.background_ios {
  background: url('../../i/download-ios-page-vk-teams.png') no-repeat 50% 138px;
  background-size: 318px auto;

  @media (min-width: 375px) {
    background-position: 50% 151px;
    background-size: 205px auto;
  }

  @media (min-width: 768px) {
    background-position: 50% 141px;
    background-size: 205px auto;
  }

  @media (min-width: 980px) {
    background-position: 50% 141px;
    background-size: 220px auto;
  }

  @media (min-width: 1900px) {
    background-position: 50% 161px;
    background-size: 240px auto;
  }

  @media (min-width: 2200px) {
    background-position: 50% 171px;
    background-size: 276px auto;
  }
}

.background_android {
  background: url('../../i/download-android-page-vk-teams.png') no-repeat 50% 138px;
  background-size: 318px auto;

  @media (min-width: 375px) {
    background-position: 50% 151px;
    background-size: 205px auto;
  }

  @media (min-width: 768px) {
    background-position: 50% 141px;
    background-size: 205px auto;
  }

  @media (min-width: 980px) {
    background-position: 50% 141px;
    background-size: 220px auto;
  }

  @media (min-width: 1900px) {
    background-position: 50% 161px;
    background-size: 240px auto;
  }

  @media (min-width: 2200px) {
    background-position: 50% 171px;
    background-size: 276px auto;
  }
}

.-vkteams {
  .download-page {

    @media (min-width: 980px) {
      padding-top: 345px;
    }

    @media (min-width: 1900px) {
      padding-top: 395px;
    }

    @media (min-width: 2200px) {
      padding-top: 448px;
    }

    h1 {
      color: #000000;
    }

    &__item {
      &.-windows:before {
        background-image: url('../../i/ic-windows-vkteams.png');
      }

      &.-web:before {
        background-image: url('../../i/ic-web-vkteams.png');
      }

      &.-android:before {
        background-image: url('../../i/ic-android-vkteams.png');
      }

      &.-linux:before {
        background-image: url('../../i/ic-linux-vkteams.png');
      }

      &.-mac:before {
        background-image: url('../../i/ic-mac-vkteams.png');
      }

      &.-ios:before {
        background-image: url('../../i/ic-ios-vkteams.png');
      }

      a {
        &:after {
          background: #0077FF;
        }

        &:hover:after {
          box-shadow: 0 1px 10px 2px #0077FF;
        }
      }
    }
  }
}
