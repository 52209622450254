.download-inner {
  width: 155px;
  font-size: 0;
  line-height: 0;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 375px) {
    width: auto;
  }

  &__item {
    display: inline-block;
    border-radius: 6px;
    transition: box-shadow 0.15s cubic-bezier(.42, 0, .58, 1);

    &:not(:last-child) {
      margin-bottom: 15px;

      @media (min-width: 375px) {
        margin-bottom: 0;
        margin-right: 19px;
      }

      @media (min-width: 768px) {
        margin-right: 14px;
      }

      @media (min-width: 980px) {
        margin-right: 25px;
      }
    }

    &:hover {
      box-shadow: 0px 0px 10px #22F200;
    }
  }

  img {
    display: block;
    width: auto;
    height: 44px;

    @media (min-width: 768px) and (max-width: 979px) {
      width: auto;
      height: 38px;
    }
  }
}
