@font-face {
    font-family: 'VKSans';
    src: url('../fonts/VK-Sans-Text-Light.ttf');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'VKSans';
    src: url('../fonts/VK-Sans-Text-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
